import * as React from "react";
import styles from "./Footer.module.css"
import styled from "styled-components";

const Subtitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const UseTermsModalContent = () => {
    return(
        <>
        <Subtitle className={styles["modalContentSubtitle"]}>
            <div>
                {"г. Калуга"}
            </div>
            <div>
                {(new Date()).toLocaleDateString()}
            </div>
        </Subtitle>
        <br /><br />

        <div className={styles["modalContentSubtitle"]}>
            {"1. Общие положения\n"}
        </div>
        <div className={styles["modalContent"]}>
            {
                "\n" +
                "1.1.\tНастоящее Пользовательское соглашение (далее – Соглашение) относится к сайту Интернет-магазина «Гурман», расположенному \n" +
                "по адресу гурман-калуга.рф, и ко всем соответствующим сайтам, связанным с сайтом www.гурман-калуга.рф. \n" +
                "\n" +
                "1.2.\tСайт Интернет-магазина «Гурман» (далее – Сайт) является собственностью ИП Багирян Ануш Геннадьевна\n" +
                "\n" +
                "1.3.\tНастоящее Соглашение регулирует отношения между Администрацией сайта Интернет-магазина «Гурман» (далее – Администрация сайта) и Пользователем данного Сайта.\n" +
                "\n" +
                "1.4. Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.\n" +
                "\n" +
                "1.5. Продолжение использования Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.\n" +
                "\n" +
                "1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.\n"
            }
        </div>

        <div className={styles["modalContentSubtitle"]}>
            {"2. Определения терминов\n"}
        </div>
        <div className={styles["modalContent"]}>
            {
                "\n" +
                "2.1.\tПеречисленные ниже термины имеют для целей настоящего Соглашения следующее значение:\n" +
                "\n" +
                "2.1.1 «Гурман» – Интернет-магазин, расположенный на доменном имени www.гурман-калуга.рф, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов.\n" +
                "\n" +
                "2.1.2. Интернет-магазин – сайт, содержащий информацию о Товарах, Продавце, позволяющий осуществить выбор, заказ и (или) приобретение Товара. \n" +
                "\n" +
                "2.1.3. Администрация сайта Интернет-магазина – уполномоченные сотрудники на управления Сайтом, действующие от имени Гурман.\n" +
                "\n" +
                "2.1.4. Пользователь сайта Интернет-магазина (далее   Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.\n" +
                "\n" +
                "2.1.5. Содержание сайта Интернет-магазина (далее – Содержание) - охраняемые результаты интеллектуальной деятельности, включая тексты литературных произведений, их названия, предисловия, аннотации, статьи, иллюстрации, обложки, музыкальные произведения с текстом или без текста, графические, текстовые, фотографические, производные, составные и иные произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте Интернет-магазина.\n"
            }
        </div>

        <div className={styles["modalContentSubtitle"]}>
            {"3. Предмет соглашения\n"}
        </div>
        <div className={styles["modalContent"]}>
            {
                "\n" +
                "3.1. Предметом настоящего Соглашения является предоставление Пользователю Интернет-магазина доступа к содержащимся на Сайте Товарам и оказываемым услугам.\n" +
                "\n" +
                "3.1.1. Интернет-магазин предоставляет Пользователю следующие виды услуг (сервисов):\n" +
                "•\tдоступ к средствам поиска и навигации Интернет-магазина;\n" +
                "•\tдоступ к информации о Товаре и к информации о приобретении Товара на  платной основе;\n" +
                "•\tиные виды услуг (сервисов), реализуемые на страницах Интернет-магазина.\n" +
                "\n" +
                "3.1.2. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы) Интернет-магазина, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы) Интернет-магазина.\n" +
                "\n" +
                "3.2. Доступ к Интернет-магазину предоставляется на безвозмедной основе.\n" +
                "\n" +
                "3.3. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь считается присоединившимся к настоящему Соглашению. \n" +
                "\n" +
                "3.4. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации\n"
            }
        </div>

        <div className={styles["modalContentSubtitle"]}>
            {"4. Права и обязанности сторон\n"}
        </div>
        <div className={styles["modalContent"]}>
            {
                "\n" +
                "4.1. Администрация сайта вправе:\n" +
                "\n" +
                "4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.\n" +
                "\n" +
                "4.1.2. Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего Соглашения.\n" +
                "\n" +
                "4.1.3. Изменять размер оплаты, взимаемый за предоставление доступа к использованию сайта Интернет-магазина. Изменение стоимости не будет распространяться на Пользователей, имеющих регистрацию к моменту изменения размера оплаты, за исключением случаев, особо оговоренных Администрацией сайта интернет-магазина.\n" +
                "\n" +
                "4.2. Пользователь вправе: \n" +
                "\n" +
                "4.2.1. Получить доступ к использованию Сайта после соблюдения требований о регистрации оплате.\n" +
                "\n" +
                "4.2.2. Пользоваться всеми имеющимися на Сайте услугами, а также приобретать любые Товары, предлагаемые на Сайте.\n" +
                "\n" +
                "4.2.3. Задавать любые вопросы, относящиеся к услугам Интернет-магазина по реквизитам, которые находятся в разделе Сайта «Полные реквизиты».\n" +
                "\n" +
                "4.2.4. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации.\n" +
                "\n" +
                "4.3. Пользователь Сайта обязуется:\n" +
                "\n" +
                "4.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта.\n" +
                "\n" +
                "4.3.2. Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании Сайта.\n" +
                "\n" +
                "4.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.\n" +
                "\n" +
                "4.3.4. Не распространять с использованием Сайта любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах. \n" +
                "\n" +
                "4.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.\n" +
                "\n" +
                "4.3.6. Не использовать Сайт для распространения информации рекламного характера, иначе как с согласия Администрации сайта.\n" +
                "\n" +
                "4.3.7. Не использовать сервисы сайта Интернет-магазина с целью: \n" +
                "\n" +
                "4.3.7. 1. загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти. \n" +
                "\n" +
                "4.3.7. 2. побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.\n" +
                "\n" +
                "4.3.7. 3. нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме. \n" +
                "\n" +
                "4.3.7. 4. ущемления прав меньшинств.\n" +
                "\n" +
                "4.3.7. 5. представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав, в том числе за сотрудников данного Интернет-магазина.\n" +
                "\n" +
                "4.3.7. 6. введения в заблуждение относительно свойств и характеристик какого-либо Товара из каталога Интернет-магазина, размещенного на Сайте.\n" +
                "\n" +
                "4.3.7. 7. некорректного сравнения Товара, а также формирования негативного отношения к лицам, (не) пользующимся определенными Товарами, или осуждения таких лиц.\n" +
                "\n" +
                "4.4. Пользователю запрещается:\n" +
                "\n" +
                "4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта данного Интернет-магазина;\n" +
                "\n" +
                "4.4.2. Нарушать надлежащее функционирование Сайта;\n" +
                "\n" +
                "4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами данного Сайта;\n" +
                "\n" +
                "4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте;\n" +
                "\n" +
                "4.4.5. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту. \n" +
                "\n" +
                "4.4.6. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Сайта.\n" +
                "\n" +
                "4.4.7. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права интернет-магазина или других лиц.\n"
            }
        </div>

        <div className={styles["modalContentSubtitle"]}>
            {"5. Использование сайта интернет-магазина\n"}
        </div>
        <div className={styles["modalContent"]}>
            {
                "\n" +
                "5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта.\n" +
                " \n" +
                "5.2. Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или распространено любым способом, а также размещено в глобальной сети «Интернет» без предварительного письменного согласия Администрации сайта.\n" +
                "\n" +
                "5.3. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной конкуренции.\n" +
                "\n" +
                "5.4. Приобретение Товара, предлагаемого на Сайте, может потребовать создания учётной записи Пользователя.\n" +
                "\n" +
                "5.5. Пользователь несет персональную ответственность за сохранение конфиденциальности информации учётной записи, включая пароль, а также за всю без исключения деятельность, которая ведётся от имени Пользователя учётной записи. \n" +
                "\n" +
                "5.6. Пользователь должен незамедлительно уведомить Администрацию сайта о несанкционированном использовании его учётной записи или пароля или любом другом нарушении системы безопасности. \n" +
                "\n" +
                "5.7. Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия о покупке Товара и оказанию услуг, предоставляемых на Сайте. \n" +
                "\n" +
                "5.8. Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.\n" +
                "\n" +
                "5.9. Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) в цены, применимые к таким Товарам по их реализации и (или) оказываемым услугам Интернет-магазином.\n" +
                "\n" +
                "5.10. Документы, указанные в пунктах 5.10.1 - 5.10.4 настоящего Соглашения регулируют в соответствующей части и распространяют свое действие на использование Пользователем Сайта. В настоящее Соглашение включены следующие документы:\n" +
                "\n" +
                "5.10.1. Политика конфиденциальности;\n" +
                "\n" +
                "5.10.2. Договор купли-продажи товаров дистанционным способом;\n" +
                "\n" +
                "5.10.3. Заявка на оформление заказа;\n" +
                "\n" +
                "5.10.4. Предложения и замечания.\n" +
                "\n" +
                "5.11. Любой из документов, перечисленных в пункте 5.10. настоящего Соглашения может подлежать обновлению. Изменения вступают в силу с момента их опубликования на Сайте.\n"
            }
        </div>

        <div className={styles["modalContentSubtitle"]}>
            {"6. Ответственность\n"}
        </div>
        <div className={styles["modalContent"]}>
            {
                "\n" +
                "6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.\n" +
                "\n" +
                "6.2. Администрация сайта не несет ответственности за:\n" +
                "\n" +
                "6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.\n" +
                "\n" +
                "6.2.2. Действия систем переводов, банков, платежных систем и за задержки, связанные с их работой.\n" +
                "\n" +
                "6.2.3. Надлежащее функционирование Сайта в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами. \n"
            }
        </div>

        <div className={styles["modalContentSubtitle"]}>
            {"7. Нарушение условий пользовательского соглашения\n"}
        </div>
        <div className={styles["modalContent"]}>
            {
                "\n" +
                "7.1. Администрация сайта вправе раскрыть любую собранную о Пользователе данного Сайта информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права Администрации сайта или в права других Пользователей Сайта.\n" +
                "\n" +
                "7.2. Администрация сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящего Соглашения, защиты прав или безопасности ИП Багирян Ануш Геннадьевна, Пользователей.\n" +
                "\n" +
                "7.3. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.\n" +
                "\n" +
                "7.4. Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы.\n" +
                "\n" +
                "7.5. Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сайтом.\n"
            }
        </div>

        <div className={styles["modalContentSubtitle"]}>
            {"8. Разрешение споров\n"}
        </div>
        <div className={styles["modalContent"]}>
            {
                "\n" +
                "8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).\n" +
                "\n" +
                "8.2. Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.\n" +
                "\n" +
                "8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.\n" +
                "\n" +
                "8.4. Любой иск в отношении условий использования Сайта должен быть предъявлен в течение недели после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с законодательством материалы Сайта. При нарушении условий данного пункта любой иск или основания для иска погашаются исковой давностью.\n"
            }
        </div>

        <div className={styles["modalContentSubtitle"]}>
            {"9. Дополнительные условия\n"}
        </div>
        <div className={styles["modalContent"]}>
            {
                "\n" +
                "9.1. Администрация сайта не принимает встречные предложения от Пользователя относительно изменений настоящего Пользовательского соглашения.\n" +
                " \n" +
                "9.2. Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и могут быть использованы Администрацией сайта без ограничений.\n"
            }
        </div>

        <div className={styles["modalContentSubtitle"]}>
            {"Обновлено «14» 05. 2022 г.\n"}
        </div>
    </>
    )
}

export default UseTermsModalContent